import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";



const colourStylesR = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '100%', minHeight: '10% !important', fontSize: 'large', marginTop: '1%', paddingLeft:'1%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%',maxWidth: '101%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '101%',
        //paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    }
}));

const Telegram = () =>{
    const [ depotOption, setDepotOption ] = useState()
    const [ openAdd, setOpenAdd ] = useState(false)
    const [ newChannelName, setNewChannelName ] = useState()
    const [ newChannelId, setNewChannelId ] = useState()
    const [ newBotToken, setNewBotToken ] = useState()
    const [ warningName, setWarningName ] = useState()
    const [ warningId, setWarningId ] = useState()
    const [ warningToken, setWarningToken ] = useState()
    const classes = useStyles();
    const [formState, setFormState] = useState({
        channels: []
    });
    const [ openDel, setOpenDel ] = useState(false)
    const [ delChannel, setDelChannel] = useState()
    const [ warningDel, setWarningDel ] = useState()
    const [ message, setMessage ] = useState()
    const [ warningMessage, setWarningMessage ] = useState()
    const [ warningChannels, setWarningChannels ] = useState()
    const [ channelsData, setChannelsData ] = useState([])
    const [ logCheck, setLogCheck ] = useState(true)

    useEffect(()=>{
        UserService.getChannels().then(
            response =>{
                let data = []
                let res = response.data
                setChannelsData(res)
                res?.map(row =>{
                    data.push({value: row.channel_name, label: row.channel_name})
                })
                setDepotOption(data)
            }
        ).catch(err =>{
            if(err.message == "Request failed with status code 403"){
                setLogCheck(false)
            }
        })
    },[])

    const handleNewName = (e) =>{
        setNewChannelName(e.target.value)
        if(e.target.value?.length > 0){
            setWarningName("")
        }else{
            setWarningName("channel name require")
        }
    }
    const hadnleNewId = (e) =>{
        setNewChannelId(e.target.value)
        if(e.target.value?.length > 0){
            setWarningId("")
        }else{
            setWarningId("channel id require")
        }
    }
    const hadnleNewToken =(e) =>{
        setNewBotToken(e.target.value)
        if(e.target.value?.length > 0){
            setWarningToken("")
        }else{
            setWarningToken("bot token require")
        }
    }

    const handleAddNew = () =>{
        if(newChannelName && newChannelId && newBotToken){
            let data = {channel_name: newChannelName, channel_id: newChannelId, bot_token: newBotToken}
            UserService.insertChannel(data).then(
                response =>{
                    console.log(response)
                    window.location.reload(false)
                }
            )
        }else{
            if(!newChannelName){
                setWarningName("channel name require")
            }else{
                setWarningName("")
            }
            if(!newChannelId){
                setWarningId("channel id require")
            }else{
                setWarningId("")
            }
            if(!newBotToken){
                setWarningToken("bot token require")
            }else{
                setWarningToken("")
            }
        }
    }
    const handleCloseAdd = () =>{
        setOpenAdd(false)
    }
    const handleDel = (e) =>{
        setDelChannel(e.value)
        setWarningDel("")
    }
    const handleDelChannel = () =>{
        if(delChannel){
            UserService.deleteChannel({channel_name: delChannel}).then(
                response =>{
                    console.log(response)
                    window.location.reload(false)
                }
            )
        }else{
            setWarningDel("channel required")
        }
    }
    const handleCloseDel = () =>{
        setOpenDel(false)
    }

    const handleFieldChange = event => {
        //event.persist();
        setFormState(formState => ({
          ...formState,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value
        }));
    };

    const handleMessage = (e) =>{
        setMessage(e.target.value)
        if(e.target.value?.length > 0){
            setWarningMessage("")
        }else{
            setWarningMessage("message required")
        }
    }

    useEffect(()=>{
        if(formState.channels?.length > 0){
            setWarningChannels("")
        }else{
            setWarningChannels("at least one channel is required")
        }
    },[formState])

    const handleSubmit = () =>{
        if(formState.channels?.length > 0 && message ){
            let len = formState.channels?.length
            let data = []
            for(let i = 0; i < len; i++){
                channelsData?.map(row =>{
                    if(row.channel_name == formState.channels[i]){
                        data.push(row)
                    }
                })
            }
            let sendData = { channels: data, message: message}
            UserService.sendMessageChannels(sendData).then(
                response =>{
                    console.log(response)
                }
            )

        }else{
            if(!message){
                setWarningMessage("message required")
            }else(
                setWarningMessage("")
            )
            if(formState.channels?.length == 0){
                setWarningChannels("at least one channel is required")
            }else{
                setWarningChannels("")
            }
        }
    }

    return(
        <div className="incidentArch">
            {logCheck ? 
            <>    
                <h3 className="h3TitlesCorrection">Telegram</h3>
                <div className="flex">
                    <div className="flexCompX topC">
                        <label  className="label2 ">Select Channels</label>
                        <TextField
                            classes={{ root: classes.root }}
                            select
                            name="channels"
                            id="channels"
                            variant="outlined"
                            label="channels"
                            SelectProps={{
                                multiple: true,
                                value: formState.channels,
                                onChange: handleFieldChange
                            }}
                        >
                            {depotOption?.map(row => (
                            <MenuItem key={row.value} value={row.value}>
                                {row.value}
                            </MenuItem>
                            ))}
                        </TextField>
                        {warningChannels ? <p className="warning">{warningChannels}</p> : ''}
                    </div>
                    <div className="buttonsTelegram" style={{marginTop: '5%', marginLeft:'10px'}}>
                        
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenAdd(true)}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>+ Channel</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white', marginLeft:'10px'}} onClick={e => setOpenDel(true)}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>- Channel</span>
                            <div class="fill-container"></div>
                        </button>
                    </div>
                </div>
                <label  className="label2 pCorrection">Message <span style={{color: '#b30000'}}>*</span></label>
                <div className="flexComp">
                        <textarea className="lone "rows={10} value={message ? message : ''} onChange={e => handleMessage(e)}></textarea>
                        {warningMessage ? <p className="warning">{warningMessage}</p> : ''}
                </div>
                
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSubmit}>
                    <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Send</span>
                    <div class="fill-container"></div>
                </button>
                <React.Fragment>
                    <Dialog
                        //fullScreen
                        open={openAdd}
                        //TransitionComponent={Transition}
                        keepMounted
                        //onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description">
                        <DialogTitle>{}</DialogTitle>
                        <DialogContent >
                            <div className="flex ">
                            <div className="flexComp">
                                        <label for="name" className="label2 ">Channel name <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                                        <input type="text" name="name" className="lonePm " value={newChannelName ? newChannelName : ''} onChange={e => handleNewName(e)}></input>
                                        {warningName ? <p className="warning">{warningName}</p> : ''}
                                </div>
                            </div>
                            <div className="flex ">
                            <div className="flexComp">
                                        <label for="chId" className="label2 ">Channel Id <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                                        <input type="text" name="chId" className="lonePm " value={newChannelId ? newChannelId : ''} onChange={e => hadnleNewId(e)}></input>
                                        {warningId ? <p className="warning">{warningId}</p> : ''}
                                </div>
                            </div>
                            <div className="flex ">
                            <div className="flexComp">
                                        <label for="token" className="label2 ">Bot Token<span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>(require)</span></label>
                                        <input type="text" name="token" className="lonePm " value={newBotToken ? newBotToken : ''} onChange={e => hadnleNewToken(e)}></input>
                                        {warningToken ? <p className="warning">{warningToken}</p> : ''}
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {/*<button className="buttonSS" onClick={handleAddNew}>Add</button>*/}
                            <button class="buttonSkOn" style={{marginTop: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleAddNew}>
                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Add</span>
                                <div class="fill-container"></div>
                            </button>
                            {/*<button className="buttonSS" onClick={handleCloseAdd}>Close</button>*/}
                            <button class="buttonSkOn" style={{marginTop: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseAdd}>
                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                                <div class="fill-container"></div>
                            </button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
                <React.Fragment sx={{overflow: 'visible'}}>
                    <Dialog
                        //fullScreen
                        
                        open={openDel}
                        //TransitionComponent={Transition}
                        keepMounted
                        //onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description">
                        <DialogTitle>{}</DialogTitle>
                        <DialogContent >
                            <label for="depotInput" className="label2">Channel</label>
                            <Select 
                                options={depotOption}
                                styles={colourStyles}
                                //value={{label: status, value: status}}
                                onChange={e => handleDel(e)}
                            /> 
                            {warningDel ? <p className="warning">{warningDel}</p> : ''}
                        </DialogContent>
                        <DialogActions>
                            {/*<button className="buttonSS" onClick={handleDelChannel}>Delete</button>*/}
                            <button class="buttonSkOn" style={{marginTop: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleDelChannel}>
                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Delete</span>
                                <div class="fill-container"></div>
                            </button>
                            {/*<button className="buttonSS" onClick={handleCloseDel}>Close</button>*/}
                            <button class="buttonSkOn" style={{marginTop: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseDel}>
                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                                <div class="fill-container"></div>
                            </button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </>:
            <>
                <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
            </>
            }
        </div>
    )
}
export default Telegram;
