import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = createRoot(container);
window.scrollTo(0,0)
root.render(
  <BrowserRouter>
    <App />
    <footer className="footer">
      Copyright 2024 SKSO Ltd
    </footer>
  </BrowserRouter>
);

serviceWorker.unregister();
