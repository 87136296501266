import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";


const Bank = () =>{
    const user = AuthService.getCurrentUser()
    const [ name, setName ] = useState()
    const [ bankName, setBankName ] = useState()
    const [ sort, setSort ] = useState()
    const [ accountNo, setAccountNo ] = useState()
    const [ refNo, setRefNo ] = useState()
    const [ checked, setChecked ] = useState() 
    const [ check, setCheck ] = useState()
    const [ confMessage, setConfMEssage ] = useState()

    useEffect(()=>{
        const name = {name: user?.username}
        UserService.getDashData(name).then(
            response =>{
                let data = response.data
                setName(data.active_bank_name)
                setBankName(data.active_bank)
                setSort(data.active_bank_sort_code)
                setAccountNo(data.active_bank_account_no)
                setRefNo(data.active_bank_ref)
            }
        ).catch(err => console.log(err))
    },[])

    const handleSort = (e) =>{
        if(e.target.value?.length <= 8){
            setSort(e.target.value)
            if(e.target.value.length > sort.length){
                if(e.target.value?.length == 2 || e.target.value?.length == 5){
                    setSort(e.target.value+'-')
                } 
            }
        }
    }
    const handleAccountNo = (e) =>{
        if(e.target.value?.length <= 8){
            setAccountNo(e.target.value)
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSubmit = () =>{
        let data = {name: user.username, active_bank_name: name, active_bank: bankName, active_bank_sort_code: sort, active_bank_account_no: accountNo, active_bank_ref: refNo}
        UserService.updatePDetails(data).then(
            response =>{
                setCheck(true)
                setConfMEssage(<h3 style={{color: 'green'}}>Bank details updated!</h3>)
                sleep(3000).then(()=>{
                    setCheck(false)
                    window.location.reload(false)
                })
            }
        ).catch(err => {
            console.log(err)
            setConfMEssage(<h3 style={{color: 'red'}}>Something went wrong, please refresh and try again!</h3>)
        })
    }


    return(
        <>
            {!check ? 
            <>
                <h3 className="h3Cap"><b>Bank Account Details</b></h3>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Account Holders Name </label>
                            <input type="text" className="lonePm mobileInput" value={name ? name : ''} onChange={e => setName(e.target.value)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                </div>
                <p className="pClass" style={{marginLeft: '10px'}}>Make sure the accounts holders name you enter matches the account details</p>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Bank Account Name </label>
                            <input type="text" className="lonePm mobileInput" value={bankName ? bankName : ''} onChange={e => setBankName(e.target.value)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                </div>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Sort Code </label>
                            <input type="text" className="lonePm mobileInput" value={sort ? sort : ''} onChange={e => handleSort(e)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                </div>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Account No </label>
                            <input type="text" className="lonePm mobileInput" value={accountNo ? accountNo : ''} onChange={e => handleAccountNo(e)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                </div>
                <div className="flex">
                    <div className="flexComp">
                            <label  className="label2">Reference No </label>
                            <input type="text" className="lonePm mobileInput" value={refNo ? refNo : ''} onChange={e => setRefNo(e.target.value)} ></input>
                            {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                    </div>
                </div>
                <p className="pClass" style={{marginLeft: '10px'}}>This applies to UK Building Society Accounts where a reference no is required to make payments. For most bank accounts this is not required and can be left blank</p>
                <div className="flex" style={{marginTop: '20px'}}>
                    <div className="flexComp">
                            <label  className="label2">Confirmation <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                            <div className="flex mobileInputFlex">
                                <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" onChange={e => setChecked(e.target.checked)}/>
                                <label for="vehicle3" style={{marginLeft: '5px', color: 'gray'}}> I confirm the details entered are correct</label>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                            </div>
                    </div>
                </div>
                <div className="lastDiv">
                    <p className="pClass">You must check and confirm the details are correct before you can submit them.</p>
                    <p className="pClass">All entries are made at your own risk, Skilled Solutions hold no liability for any errors that occur as a result of incorrect information</p>
                </div>
                {checked ? 
                <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSubmit}>
                    <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                    <div class="fill-container"></div>
                {/*<button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#2596be'}} onClick={handleSubmit}>
                    <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                    <div class="fill-container"></div>
                </button>*/}
                </button>
                : ''
                }
            </> : <>{confMessage}</>
            }
        </>
    )
}
export default Bank;