const people = [
    {value: "Dan Dewey", label: "Dan Dewey"},
    {value: "Garth James", label: "Garth James"},
    {value: "Maddison Cross", label: "Maddison Cross"},
    {value: "Ewelina Lukaszewska", label: "Ewelina Lukaszewska"},
    {value: "Nikolay Zhelev", label: "Nikolay Zhelev" },
    {value: "Ventsislav Karaganov", label: "Ventsislav Karaganov"},
    {value: "George Willis", label: "George Willis"},
    {value: "Robert John Wells", label: "Robert John Wells"}
]
export default people;