import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';


const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , width: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const statusOptions = [
    {value: 'Pending', label: 'Pending'},
    {value: 'Onboarding', label: 'Onboarding'},
    {value: 'Active', label: 'Active'},
    {value: 'Inactive', label: 'Inactive'}
]


const Archive = () =>{
    const [ archiveData, setArchiveData ] = useState([])
    const [ archiveOptions, setArchiveOptions ] = useState([])
    const [ name, setName ] = useState()
    const [ phone, setPhone ] = useState()
    const [ email, setEmail ] = useState()
    const [ status, setStatus ] = useState()
    const [ warningName, setWarningName ] = useState()
    const [ warningStatus, setWarningStatus ] = useState()
    const [ submit, setSubmit ] = useState("")
    const [ logCheck, setLogCheck ] = useState(true)

    useEffect(()=>{
        UserService.getArchiveData().then(
            response =>{
                console.log(response.data)
                setArchiveData(response.data)
            }
        ).catch(err =>{
            if(err.message == "Request failed with status code 403"){
                setLogCheck(false)
            }
        })
    },[])

    useEffect(()=>{
        let data = []
        
        archiveData?.map(row =>{
            data.push({value: row.name, label: row.name})
        })
        setArchiveOptions(data)
    },[archiveData])

    const handleName = (e) =>{
        setName(e.value)
        archiveData?.map(row =>{
            if(row.name == e.value){
                setPhone(row.phone)
                setEmail(row.email)
            }
        })
        setWarningName("")
    }

    const handleStatus = (e) =>{
        setStatus(e.value)
        setWarningStatus("")
    }

    const updateData = () =>{
        if( name && status){
            let dataSend = {}
            archiveData?.map(row =>{
                if(name == row.name){
                    dataSend = row
                }
            })
            dataSend.status = status
            console.log(dataSend)
            UserService.updateArchive(dataSend).then(
                response =>{
                    console.log(response)
                    setSubmit("Contact moved to back to associates.")
                }
            ).catch(err => setSubmit("Something went wrong. Please refresh and try again."))
        }else{
            if(!name){
                setWarningName("name required")
            }else{
                setWarningName("")
            }
            if(!status){
                setWarningStatus("status required")
            }else{
                setWarningStatus("")
            }
        }
    }


    return(
        <div className="incidentArch">
            {logCheck ? 
                <>
                    <h3 className="h3TitlesCorrection">Archive</h3>
                    {!submit ?
                    <div>
                        <div className="flex topC">
                            <div className="flexComp">
                                    <label for="name" className="label2 ">Name <span style={{color: '#b30000'}}>*</span></label>
                                    <Select 
                                        options={archiveOptions ? archiveOptions : ''}
                                        styles={colourStyles}
                                        //value={{label: status, value: status}}
                                        onChange={e => handleName(e)}
                                    /> 
                            </div>
                        </div>
                        
                        {warningName ? 
                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>name required</p> : ''
                        }
                        <div className="flex topC">
                            <div className="flexComp">
                                <label for="address1" className="label2">Phone No</label>
                                <input type="text" name="address1" className="inputEdit" value={phone ? phone : ''} /*onChange={e => setAddress1(e.target.value)}*/ disabled></input>
                            </div>
                            <div className="flexComp">
                                <label for="address2" className="label2 ">Email</label>
                                <input type="text" name="address2" className="inputEdit " value={email ? email : ''} /*onChange={e => setAddress2(e.target.value)}*/ disabled></input>
                            </div>
                        </div>
                        <div className="flex topC">
                            <div className="flexComp">
                                    <label for="name" className="label2 ">Status <span style={{color: '#b30000'}}>*</span></label>
                                    <Select 
                                        options={statusOptions}
                                        styles={colourStyles}
                                        //value={{label: status, value: status}}
                                        onChange={e => handleStatus(e)}
                                    /> 
                            </div>
                        </div>
                        {warningStatus ? 
                            <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>new status required</p> : ''
                        }
                        
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={updateData}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Update</span>
                            <div class="fill-container"></div>
                        </button>
                    </div> : 
                    <div>
                        <h3 className="thank">{submit}</h3>
                    </div>    
                    }
                </>:
                <>
                    <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
                </>
            }
        </div>
    )
}
export default Archive;