import React from "react";
import { useState, useEffect } from "react";


const TAndC = () =>{

    return(
        <>
            
        </>
    )
}
export default TAndC;