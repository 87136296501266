const nationalities = [
    {
        value: "Prefer not to say"
    },
    {
        value: "Afghan"
    },
    {
        
        value: "\u00c5land Island"
    },
    {
        value: "Albanian"
    },
    {
        value: "Algerian"
    },
    {
        value: "American Samoan"
    },
    {
        value: "Andorran"
    },
    {
        value: "Angolan"
    },
    {
        
        value: "Anguillan"
    },
    {
    
        value: "Antarctic"
    },
    {
    
        value: "Antiguan or Barbudan"
    },
    {
        value: "Argentine"
    },
    {
        value: "Armenian"
    },
    {
        value: "Aruban"
    },
    {
        value: "Australian"
    },
    {
        value: "Austrian"
    },
    {
        value: "Azerbaijani, Azeri"
    },
    {
        value: "Bahamian"
    },
    {
        value: "Bahraini"
    },
    {
        value: "Bangladeshi"
    },
    {
        value: "Barbadian"
    },
    {
        value: "Belarusian"
    },
    {
        value: "Belgian"
    },
    {
        value: "Belizean"
    },
    {
        value: "Beninese, Beninois"
    },
    {
        value: "Bermudian, Bermudan"
    },
    {
        value: "Bhutanese"
    },
    {
    
        value: "Bolivian"
    },
    {
        
        value: "Bonaire"
    },
    {
    
       value: "Bosnian or Herzegovinian"
    },
    {
    
        value: "Motswana, Botswanan"
    },
    {
    
        value: "Bouvet Island"
    },
    {
        value: "Brazilian"
    },
    {
    
        value: "BIOT"
    },
    {
    
        value: "Bruneian"
    },
    {
        
        value: "Bulgarian"
    },
    {
        value: "Burkinab\u00e9"
    },
    {
        value: "Burundian"
    },
    {
        
        value: "Cabo Verdean"
    },
    {        
        value: "Cambodian"
    },
    {        
        value: "Cameroonian"
    },
    {
        value: "Canadian"
    },
    {
        value: "Caymanian"
    },
    {
        value: "Central African"
    },
    {
        value: "Chadian"
    },
    {
        value: "Chilean"
    },
    {
        value: "Chinese"
    },
    {
        value: "Christmas Island"
    },
    {
        value: "Cocos Island"
    },
    {        
        value: "Colombian"
    },
    {
        value: "Comoran, Comorian"
    },
    {
        value: "Congolese"
    },
    {
        value: "Congolese"
    },
    {
        
        value: "Cook Island"
    },
    {
        
        value: "Costa Rican"
    },
    {
        value: "Ivorian"
    },
    {
        value: "Croatian"
    },
    {
        value: "Cuban"
    },
    {
        
        value: "Cura\u00e7aoan"
    },
    {
        value: "Cypriot"
    },
    {
        value: "Czech"
    },
    {
        value: "Danish"
    },
    {        
        value: "Djiboutian"
    },
    {        
        value: "Dominican"
    },
    {
        value: "Dominican"
    },
    {
        value: "Ecuadorian"
    },
    {
        value: "Egyptian"
    },
    {
        
        value: "Salvadoran"
    },
    {
        value: "Equatorial Guinean, Equatoguinean"
    },
    {
        value: "Eritrean"
    },
    {
        value: "Estonian"
    },
    {        
        value: "Ethiopian"
    },
    {
        value: "Falkland Island"
    },
    {
        
        value: "Faroese"
    },
    {
        value: "Fijian"
    },
    {
        value: "Finnish"
    },
    {
        value: "French"
    },
    {
        
        value: "French Guianese"
    },
    {
        value: "French Polynesian"
    },
    {
        value: "French Southern Territories"
    },
    {
        value: "Gabonese"
    },
    {
        value: "Gambian"
    },
    {
        value: "Georgian"
    },
    {
        value: "German"
    },
    {
        value: "Ghanaian"
    },
    {
        
        value: "Gibraltar"
    },
    {
        value: "Greek, Hellenic"
    },
    {
        
        value: "Greenlandic"
    },
    {
        value: "Grenadian"
    },
    {
        value: "Guadeloupe"
    },
    {
        value: "Guamanian, Guambat"
    },
    {
        
        value: "Guatemalan"
    },
    {        
        value: "Channel Island"
    },
    {
        value: "Guinean"
    },
    {
        
        value: "Bissau-Guinean"
    },
    {
        value: "Guyanese"
    },
    {
        value: "Haitian"
    },
    {
        value: "Heard Island or McDonald Islands"
    },
    {
        value: "Vatican"
    },
    {        
        value: "Honduran"
    },
    {
        
        value: "Hong Kong, Hong Kongese"
    },
    {
        value: "Hungarian, Magyar"
    },
    {
        value: "Icelandic"
    },
    {
        value: "Indian"
    },
    {
        
        value: "Indonesian"
    },
    {
        value: "Iranian, Persian"
    },
    {
        value: "Iraqi"
    },
    {
        value: "Irish"
    },
    {
        value: "Manx"
    },
    {
        value: "Israeli"
    },
    {
        value: "Italian"
    },
    {
        value: "Jamaican"
    },
    {
        value: "Japanese"
    },
    {
        value: "Channel Island"
    },
    {
        value: "Jordanian"
    },
    { 
        value: "Kazakhstani, Kazakh"
    },
    {
        value: "Kenyan"
    },
    {        
        value: "I-Kiribati"
    },
    {
        value: "North Korean"
    },
    {
        value: "South Korean"
    },
    {
        value: "Kuwaiti"
    },
    {
        value: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
    },
    {
        value: "Lao, Laotian"
    },
    {
        value: "Latvian"
    },
    {
        value: "Lebanese"
    },
    {
        value: "Basotho"
    },
    {
        value: "Liberian"
    },
    {
        value: "Libyan"
    },
    {
        
        value: "Liechtenstein"
    },
    {
        
        value: "Lithuanian"
    },
    {
        
        value: "Luxembourg, Luxembourgish"
    },
    {
        value: "Macanese, Chinese"
    },
    {
        value: "Macedonian"
    },
    {
        
        value: "Malagasy"
    },
    {
        value: "Malawian"
    },
    {        
        value: "Malaysian"
    },
    {        
        value: "Maldivian"
    },
    {
        value: "Malian, Malinese"
    },
    {
        value: "Maltese"
    },
    {
        value: "Marshallese"
    },
    {
        
        value: "Martiniquais, Martinican"
    },
    {
        
        value: "Mauritanian"
    },
    {
        
        value: "Mauritian"
    },
    {
        value: "Mahoran"
    },
    {
        value: "Mexican"
    },
    {
        value: "Micronesian"
    },
    {
        value: "Moldovan"
    },
    {
        value: "Mon\u00e9gasque, Monacan"
    },
    {        
        value: "Mongolian"
    },
    {
        
        value: "Montenegrin"
    },
    {
        
        value: "Montserratian"
    },
    {
        value: "Moroccan"
    },
    {
        
        value: "Mozambican"
    },
    {
        value: "Burmese"
    },
    {
        value: "Namibian"
    },
    {
        value: "Nauruan"
    },
    {
        value: "Nepali, Nepalese"
    },
    {
        
        value: "Dutch, Netherlandic"
    },
    {
        
        value: "New Caledonian"
    },
    {
        
        value: "New Zealand, NZ"
    },
    {
        
        value: "Nicaraguan"
    },
    {
        value: "Nigerien"
    },
    {
        value: "Nigerian"
    },
    {
        value: "Niuean"
    },
    {
        value: "Norfolk Island"
    },
    {
        value: "Northern Marianan"
    },
    {
        value: "Norwegian"
    },
    {
        value: "Omani"
    },
    {        
        value: "Pakistani"
    },
    {
        value: "Palauan"
    },
    {
        value: "Palestinian"
    },
    {
        value: "Panamanian"
    },
    {
        value: "Papua New Guinean, Papuan"
    },
    {        
        value: "Paraguayan"
    },
    {
        value: "Peruvian"
    },
    {
        value: "Philippine, Filipino"
    },
    {        
        value: "Pitcairn Island"
    },
    {
    value: "Polish"
    },
    {        
        value: "Portuguese"
    },
    {
        
        value: "Puerto Rican"
    },
    {
        value: "Qatari"
    },
    {
        
        value: "R\u00e9unionese, R\u00e9unionnais"
    },
    {
        value: "Romanian"
    },
    {
        value: "Russian"
    },
    {
        value: "Rwandan"
    },
    {
        value: "Barth\u00e9lemois"
    },
    {
        value: "Saint Helenian"
    },
    {
        value: "Kittitian or Nevisian"
    },
    {
        
        value: "Saint Lucian"
    },
    {
        value: "Saint-Martinoise"
    },
    {
        value: "Saint-Pierrais or Miquelonnais"
    },
    {
        value: "Saint Vincentian, Vincentian"
    },
    {
        value: "Samoan"
    },
    {
        
        value: "Sammarinese"
    },
    {
        value: "S\u00e3o Tom\u00e9an"
    },
    {
        
        value: "Saudi, Saudi Arabian"
    },
    {
        value: "Senegalese"
    },
    {
        value: "Serbian"
    },
    {
        
        value: "Seychellois"
    },
    {
        
       value: "Sierra Leonean"
    },
    {
        
        value: "Singaporean"
    },
    {
        value: "Sint Maarten"
    },
    {        
        value: "Slovak"
    },
    {        
        value: "Slovenian, Slovene"
    },
    {
    
        value: "Solomon Island"
    },
    {
        value: "Somali, Somalian"
    },
    {
        
        value: "South African"
    },
    {
       value: "South Georgia or South Sandwich Islands"
    },
    {
        
        value: "South Sudanese"
    },
    {
        value: "Spanish"
    },
    {
        
        value: "Sri Lankan"
    },
    {
        value: "Sudanese"
    },
    {        
        value: "Surinamese"
    },
    {
        value: "Svalbard"
    },
    {
        
        value: "Swazi"
    },
    {
        value: "Swedish"
    },
    {
        
        value: "Swiss"
    },
    {
        value: "Syrian"
    },
    {
        value: "Chinese, Taiwanese"
    },
    {
        
        value: "Tajikistani"
    },
    {
        value: "Tanzanian"
    },
    {        
        value: "Thai"
    },
    {
        
        value: "Timorese"
    },
    {
        value: "Togolese"
    },
    {
        value: "Tokelauan"
    },
    {
        value: "Tongan"
    },
    {
        value: "Trinidadian or Tobagonian"
    },
    {
        value: "Tunisian"
    },
    {
        value: "Turkish"
    },
    {
        
        value: "Turkmen"
    },
    {
        value: "Turks and Caicos Island"
    },
    {
        value: "Tuvaluan"
    },
    {
        value: "Ugandan"
    },
    {
        value: "Ukrainian"
    },
    {
        value: "Emirati, Emirian, Emiri"
    },
    {
        value: "British, UK"
    },
    {
        value: "American"
    },
    {
        value: "American"
    },
    {
        value: "Uruguayan"
    },
    {
        
        value: "Uzbekistani, Uzbek"
    },
    {
        value: "Ni-Vanuatu, Vanuatuan"
    },
    {
       value: "Venezuelan"
    },
    {
        value: "Vietnamese"
    },
    {
    
        value: "British Virgin Island"
    },
    {
        value: "U.S. Virgin Island"
    },
    {
        value: "Wallis and Futuna, Wallisian or Futunan"
    },
    {
        value: "Sahrawi, Sahrawian, Sahraouian"
    },
    {
        value: "Yemeni"
    },
    {
        value: "Zambian"
    },
    {        
        value: "Zimbabwean"
    }
]

export default nationalities;