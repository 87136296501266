import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";

const Company = () =>{
    const user = AuthService.getCurrentUser();
    const [ utr, setUtr ] = useState()
    const [ companyName, setCompanyName ] = useState()
    const [ compReg, setCompReg ] = useState()
    const [ vat, setVat ] = useState()
    const [ checked, setChecked ] = useState(false)
    const [ check, setCheck ] = useState(false)
    const [ confMessage, setConfMEssage ] = useState("")

    useEffect(()=>{
        let name = {name: user.username}
        UserService.getDashData(name).then(
            response =>{
                let data = response.data
                setUtr(data.active_utr)
                setCompanyName(data.active_co_name)
                setCompReg(data.active_co_reg)
                setVat(data.active_co_vat)
            }
        ).catch(err => console.log(err))
    },[])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSubmit = () =>{
        let data = {name: user.username, active_utr: utr, active_co_name: companyName, active_co_reg: compReg, active_co_vat: vat}
        UserService.updatePDetails(data).then(
            response =>{
                setCheck(true)
                setConfMEssage(<h3 style={{color: 'green'}}>Company details updated!</h3>)
                sleep(3000).then(()=>{
                    setCheck(false)
                    window.location.reload(false)
                })
            }
        ).catch(err => {
            console.log(err)
            setConfMEssage(<h3 style={{color: 'red'}}>Something went wrong, please refresh and try again!</h3>)
        })
    }


    return(
        <>
            {!check ?
                <>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">UTR No <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                                <input type="text" className="lonePm mobileInput" value={utr ? utr : ''} onChange={e => setUtr(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <h3 className="h3Cap"><b>Company Details (If Applicable)</b></h3>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">Company Name </label>
                                <input type="text" className="lonePm mobileInput" value={companyName ? companyName : ''} onChange={e => setCompanyName(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">Company Registration No </label>
                                <input type="text" className="lonePm mobileInput" value={compReg ? compReg : ''} onChange={e => setCompReg(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">VAT No </label>
                                <input type="text" className="lonePm mobileInput" value={vat ? vat : ''} onChange={e => setVat(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <p className="pClass" style={{marginLeft: '10px'}}>applicable if your VAT Registered</p>
                    <div className="flex" style={{marginTop: '20px'}}>
                        <div className="flexComp">
                                <label  className="label2">Confirmation <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                                <div className="flex mobileInputFlex">
                                    <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" onChange={e => setChecked(e.target.checked)}/>
                                    <label for="vehicle3" style={{marginLeft: '5px', color: 'gray'}}> I confirm the details entered are correct</label>
                                    {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                                </div>
                        </div>
                    </div>
                    <div className="lastDiv">
                        <p className="pClass">You must check and confirm the details are correct before you can submit them.</p>
                        <p className="pClass">All entries are made at your own risk, Skilled Solutions hold no liability for any errors that occur as a result of incorrect information</p>
                    </div>
                    { checked ?
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSubmit}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                        <div class="fill-container"></div>
                    {/*</button><button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#2596be'}} onClick={handleSubmit}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                        <div class="fill-container"></div>
                    </button>*/}
                    </button> 
                    : ''}
                </>: <>{confMessage}</>
            }
        </>
    )
}
export default Company;