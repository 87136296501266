import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AuthService from "../../services/auth.service";

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
        
    }), 
}
const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const statusOptions = [
    {value: 'Pending', label: 'Pending'},
    {value: 'Onboarding', label: 'Onboarding'},
    {value: 'Active', label: 'Active'},
    {value: 'Inactive', label: 'Inactive'},
    {value: 'Offboarded', label: 'Offboarded'},
    {value: 'Not Suitable', label: 'Not Suitable'},
    {value: 'Not Interested', label: 'Not Interested'}
]

const EditProfile = () =>{
    const [ names, setNames ] = useState([]) 
    const [ details, setDetails ] = useState()
    const [ value, setValue ] = useState('1')
    const [ name, setName ] = useState()
    const [ station, setStation ] = useState()
    const [ account, setAccount ] = useState()
    const [ role, setRole ] = useState()
    const [ pemail, setPemail ] = useState()
    const [ wemail, setWemail ] = useState()
    const [ phone, setPhone ] = useState()
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ county, setCounty ] = useState()
    const [ postCode, setPostCode ] = useState()
    const [ dob, setDob ] = useState()
    const [ check, setCheck ] = useState(false)
    const [ entry, setEntry ] = useState()
    const [ source, setSource ] = useState()
    const [ utr, setUtr ] = useState()
    const [ compName, setCompName ] = useState()
    const [ compReg, setCompReg ] = useState()
    const [ compVat, setCompVat ] = useState()
    const [ amzl, setAmzl ] = useState()
    const [ nationality, setNationality ] = useState()
    const [ nino, setNino ] = useState()
    const [ status, setStatus ] = useState()
    const [ bankName, setBankName ] = useState()
    const [ bankHolder, setBankHolder ] = useState()
    const [ bankSc, setBankSc ] = useState()
    const [ bankAc, setBankAc ] = useState()
    const [ bankRef, setBankRef ] = useState()
    const [ payCycle, setPayCycle ] = useState()
    const [ notes, setNotes ] = useState()
    const [ user, setUser ] = useState()
    const [ logCheck, setLogCheck ] = useState(true)

    useEffect(() => {
        setUser(AuthService.getCurrentUser());  
        console.log(user) 
        UserService.getContactNames().then(
            response =>{
                let dat = []
                response.data?.map(item =>{
                    dat.push({value: item.name, label: item.name})
                })
                setNames(dat)
            }
        ).catch(err =>{
            console.log(err.message)
            if(err.message == "Request failed with status code 403"){
                setLogCheck(false)
            }
        })
    },[])
//match
    const handleDriver = (e) =>{
        
        UserService.getProfileData({name: e.value}).then(
            response =>{
                //setDetails(response.data)
                //console.log(response.data)
                console.log(response.data)
                setName(response.data.name)
                setStation(response.data.station ? response.data.station : '')
                setAccount(response.data.account_no ? response.data.account_no : '')
                setRole(response.data.skso_role ? response.data.skso_role : '')
                setPemail(response.data.email ? response.data.email : '')
                setWemail(response.data.email_skso ? response.data.email_skso : '')
                setPhone(response.data.phone ? response.data.phone : '')
                setAddress1(response.data.address1 ? response.data.address1 : '')
                setAddress2(response.data.address2 ? response.data.address2 : '')
                setTown(response.data.town ? response.data.town : '')
                setCounty(response.data.county ? response.data.county : '')
                setPostCode(response.data.post_code ? response.data.post_code : '')
                setDob(response.data.dob ? response.data.dob : '')
                setEntry(response.data.entry ? response.data.entry : '')
                setSource(response.data.vehicle_source ? response.data.vehicle_source : '')
                setUtr(response.data.active_utr ? response.data.active_utr : '')
                setCompName(response.data.active_co_name ? response.data.active_co_name : '')
                setCompReg(response.data.active_co_reg ? response.data.active_co_reg : '')
                setCompVat(response.data.active_co_vat ? response.data.active_co_vat : '')
                setAmzl(response.data.amzl_id ? response.data.amzl_id : '')
                setNationality(response.data.nationality ? response.data.nationality : '')
                setNino(response.data.nino ? response.data.nino : '')
                setStatus(response.data.status ? response.data.status : '')
                setBankName(response.data.active_bank ? response.data.active_bank : '')
                setBankHolder(response.data.active_bank_name ? response.data.active_bank_name : '')
                setBankSc(response.data.active_bank_sort_code ? response.data.active_bank_sort_code : '')
                setBankAc(response.data.active_bank_account_no ? response.data.active_bank_account_no : '')
                setBankRef(response.data.active_bank_ref ? response.data.active_bank_ref : '')
                setPayCycle(response.data.active_pay_cycle ? response.data.active_pay_cycle : '')
                setNotes(response.data.notes ? response.data.notes : '')
            }
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const UpdateBasic = () =>{
            let data = {entry: entry, name: name, account_no: account, station: station, skso_role: role, email: pemail, email_skso: wemail, phone: phone, address1: address1, address2: address2, town: town, county: county, post_code: postCode, dob: dob, nationality: nationality, nino: nino, vehicle_source: source, active_utr: utr, amzl_id: amzl, status: status}
            
            console.log(data)
            UserService.updateBasicInfo(data).then(
                response => {
                    if(response.status == 200){
                        console.log(response.status)
                        setCheck(true)
                        sleep(3000).then(()=>{
                            setCheck(false)
                            window.location.reload(false)
                        })
                    }
                    
                }
            ).catch(err => console.log(err))
        

    }

    const UpdateAdditional = () =>{
        let data = {entry: entry,  active_co_name: compName, active_co_reg: compReg, active_co_vat: compVat, notes: notes}
    //implementing --------------------------------------------------------------------
    //---------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------
    }

    return(
        <div className="incidentArch">
            {logCheck ? 
                <>
                    <h3 className="h3TitlesCorrection">Edit Info</h3>
                    <label for="select" className="label" >Associate</label>
                    <Select 
                        options={names}
                        styles={colourStylesS}
                        onChange={e => handleDriver(e)}
                        
                    /> 
                    <Box sx={{ width: '100%', typography: 'body1', marginLeft: '2%', marginTop:'2%' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Basic Info" value="1" />
                                <Tab label="Additional Info" value="2" />
                            </TabList>
                            </Box>
                                <TabPanel value="1">

                                {check == false ? 
                                    <>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="name" className="label2">Name</label>
                                                <input type="text" name="name" className="lone" value={name} onChange={e => setName(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="station" className="label2">Station</label>
                                                <input type="text" name="station" className="inputEdit" value={station} onChange={e => setStation(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="account" className="label2 correction">Account No</label>
                                                <input type="text" name="account" className="inputEdit correction" value={account} onChange={e => setAccount(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="role" className="label2 ">SKSO Role</label>
                                                    <input type="text" name="role" className="lone" value={role} onChange={e => setRole(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="pemail" className="label2">Personal Email</label>
                                                <input type="text" name="pemail" className="inputEdit" value={pemail} onChange={e => setPemail(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="wemail" className="label2 correction">SKSO Email</label>
                                                <input type="text" name="wemail" className="inputEdit correction" value={wemail} onChange={e => setWemail(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="phone" className="label2 ">Phone Number</label>
                                                    <input type="text" name="phone" className="lone " value={phone} onChange={e => setPhone(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="address1" className="label2">Address 1</label>
                                                <input type="text" name="address1" className="inputEdit" value={address1} onChange={e => setAddress1(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="address2" className="label2 correction">Address 2</label>
                                                <input type="text" name="address2" className="inputEdit correction" value={address2} onChange={e => setAddress2(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="town" className="label2 ">Town</label>
                                                    <input type="text" name="town" className="lone " value={town} onChange={e => setTown(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="county" className="label2">County</label>
                                                <input type="text" name="county" className="inputEdit" value={county} onChange={e => setCounty(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="postCode" className="label2 correction">Post Code</label>
                                                <input type="text" name="postCode" className="inputEdit correction" value={postCode} onChange={e => setPostCode(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="dob" className="label2 ">Date Of Birth</label>
                                                    <input type="text" name="dob" className="lone " value={dob} onChange={e => setDob(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="nationality" className="label2">Nationality</label>
                                                <input type="text" name="nationality" className="inputEdit" value={nationality} onChange={e => setNationality(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="nino" className="label2 correction">Nino</label>
                                                <input type="text" name="nino" className="inputEdit correction" value={nino} onChange={e => setNino(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="source" className="label2">Vehicle Source</label>
                                                <input type="text" name="source" className="lone" value={source} onChange={e => setSource(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="utr" className="label2">UTR</label>
                                                <input type="text" name="utr" className="inputEdit" value={utr} onChange={e => setUtr(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="amzlId" className="label2 correction">Amzl Id</label>
                                                <input type="text" name="amzlId" className="inputEdit correction" value={amzl} onChange={e => setAmzl(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="status" className="label2">Status</label>
                                                <Select 
                                                    options={statusOptions}
                                                    styles={colourStyles}
                                                    value={{label: status, value: status}}
                                                    onChange={e => setStatus(e.value)}
                                                /> 
                                            </div>
                                        </div>
                                        
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={UpdateBasic}>
                                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Update Basic</span>
                                            <div class="fill-container"></div>
                                        </button>
                                    </> :
                                    <label for="select" className="label" >Info Updated!!</label>
                                    }
                                </TabPanel>
                                <TabPanel value="2">
                                {check == false ? 
                                    <>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="cycle" className="label2">Pay Cycle</label>
                                                <input type="text" name="cycle" className="lone" value={payCycle} onChange={e => setPayCycle(e.target.value)}></input>
                                            </div>
                                        </div> 
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="cName" className="label2 ">Company Name</label>
                                                    <input type="text" name="cName" className="lone " value={compName} onChange={e => setCompName(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="cReg" className="label2">Company Reg</label>
                                                <input type="text" name="cReg" className="inputEdit" value={compReg} onChange={e => setCompReg(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="cVat" className="label2 correction">Company Vat</label>
                                                <input type="text" name="cVat" className="inputEdit correction" value={compVat} onChange={e => setCompVat(e.target.value)}></input>
                                            </div>
                                        </div>
                                        {user?.department?.match('Senior Management') ? 
                                            <>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                            <label for="bankName" className="label2 ">Bank Name</label>
                                                            <input type="text" name="bankName" className="lone " value={bankName} onChange={e => setBankName(e.target.value)}></input>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                        <label for="bankHolder" className="label2">Bank Holder</label>
                                                        <input type="text" name="bankHolder" className="inputEdit" value={bankHolder} onChange={e => setBankHolder(e.target.value)}></input>
                                                    </div>
                                                    <div className="flexComp">
                                                        <label for="bankRef" className="label2 correction">Bank Ref</label>
                                                        <input type="text" name="bankRef" className="inputEdit correction" value={bankRef} onChange={e => setBankRef(e.target.value)}></input>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                        <label for="sort" className="label2">Bank Sort Code</label>
                                                        <input type="text" name="sort" className="inputEdit" value={bankSc} onChange={e => setBankSc(e.target.value)}></input>
                                                    </div>
                                                    <div className="flexComp">
                                                        <label for="acc" className="label2 correction">Bank Account Number</label>
                                                        <input type="text" name="acc" className="inputEdit correction" value={bankAc} onChange={e => setBankAc(e.target.value)}></input>
                                                    </div>
                                                </div>
                                            </> : ''
                                        }
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="bankName" className="label2 ">Notes</label>
                                                    <textarea rows={3} name="bankName" className="lone " value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    
                                    {/*<button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#2596be'}} onClick={UpdateAdditional}>
                                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Update Additional</span>
                                            <div class="fill-container"></div>
                                        </button>*/}
                                    </> :
                                    <label for="select" className="label" >Info Updated!!</label>
                                    }
                                </TabPanel>
                            
                        </TabContext>
                    </Box>
                </>:
                <>
                    <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
                </>
            }
           
        </div>
    )
}
export default EditProfile;