import React, { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import MailIcon from '@mui/icons-material/Mail';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      background: 'red',
      colour: 'white'
    },
  }));

const BadgeMail = (props) =>{
    let dep = props.dep
    let ticketId = props.ticketId
    let update = props.update
    const [ count, setCount ] = useState(0)
    let interval = '';
    
    useEffect(()=>{
        let data = {column: 'seen_'+dep, ticket_id: ticketId}
        clearInterval(interval)
        if(dep == 'user'){
            interval = setInterval(()=>{
                UserService.countUnseen(data).then(
                    response =>{
                        let data = response.data
                        setCount(data.length)
                    }
                ).catch(err => console.log(err))
            },1000)
        }else{
            console.log(dep)
            interval = setInterval(()=>{
                let dataDep = {dep: dep, ticket_id: ticketId}

                UserService.countUnseenDep(dataDep).then(
                    response =>{
                        
                        let data = response.data
                        console.log(response)
                        setCount(data.count)
                    }
                ).catch(err => console.log(err))
            },1000)
        }
    },[])
    
      
    return(
        <StyledBadge badgeContent={count > 0 ? 'new': count} style={{color:'white'}}>
            <MailIcon color="action" />
        </StyledBadge>
    )
}
export default BadgeMail;