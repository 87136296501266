import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import { isEmail } from "validator";
import { withRouter } from '../common/with-router';



const vpassword = value => {
    if (value.length < 6 || value.length > 40) {
      return (
        <div className="alert alert-danger" role="alert">
          The password must be between 6 and 40 characters.
        </div>
      );
    }
};
class Reset extends Component {
  constructor(props) {
    super(props);
    this.handleReset = this.handleReset.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordC = this.onChangePasswordC.bind(this);


    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      messageReset: "",
      step: 1,
      email: "",
      confirm: "",
      time: ""
    };
  }
  

 
  
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }
  onChangePasswordC(e) {
    this.setState({
      confirm: e.target.value
    });
  }

  handleReset(e, time, sk_email) {
    e.preventDefault();
    this.setState({
      message: "",
      loading: true
    });
    console.log(time)
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0 && this.state.password == this.state.confirm) {
        
        console.log(this.state.time)
        AuthService.reset(sk_email, this.state.password, this.state.confirm, time).then(
            response => {
                console.log(response)
            this.setState({
                message: response.data.message
            })
            },
            error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            this.setState({
                loading: false,
                message: resMessage
            });
            }
        );
    } else {
        this.setState({
            message: 'Password do not match!'
        })
        this.setState({
            loading: false
        });
    }
  }

  

  render() {
    const query = new URLSearchParams(window.location.search);
    const sk_email = query.get('sk_email')
    const time = query.get('tl')
    const required = value => {
      if (!value) {
        return (
          <div className="alert alert-danger" role="alert">
            This field is required!
          </div>
        );
      }
    };
  
    
    return (
            <div className="col-md-12">
              <div className="card card-container">
                <img
                  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  alt="profile-img"
                  className="profile-img-card"
                />

                <Form
                  onSubmit={e => this.handleReset(e, time, sk_email)}
                  ref={c => {
                    this.form = c;
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="sk_email">Email: </label>
                    <h4 name="sk_email">{sk_email}</h4>
                  </div>
                  <div className="form-group">
                    <label htmlFor="new_password">New Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="new_password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required, vpassword]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="confirm_password"
                      value={this.state.confirm}
                      onChange={this.onChangePasswordC}
                      validations={[required, vpassword]}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Reset</span>
                    </button>
                  </div>
                  {this.state.message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {this.state.message}
                      </div>
                    </div>
                  )}
                  <CheckButton
                    style={{ display: "none" }}
                    ref={c => {
                      this.checkBtn = c;
                    }}
                  />
                </Form>
              </div>
            </div>
    );
  }
}

export default Reset;