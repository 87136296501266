import React from "react";
import { useState, useEffect } from "react";
import Select  from "react-select";
import UserService from "../../services/user.service";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';


const SlotsForm = () =>{
    const [ depots, setDepots ] = useState([])
    const [ dep, setDep ] = useState()
    const [ slots, setSlots ] = useState([])
    const [value, onChange] = useState('10:00');
    const [inputFields, setInputFields] = useState([
        { date: '', amount: '', time: '' }
      ])

    useEffect(()=>{
        UserService.getStations().then(
            response =>{
                let data = response.data
                data?.map(depot =>{
                    setDepots(oldDepots => [...oldDepots, {value: depot.station_code, label: depot.station_code}])
                })
            }
        )
    },[])
   
    const handleChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
     }
     const handleChangeTime = (index, event, name) => {
        console.log(name)
        let data = [...inputFields];
        data[index][name] = event;
        setInputFields(data);
     }
     const addFields = () => {
        let newfield = { date: '', amount: '', time: '10:00' }
        setInputFields([...inputFields, newfield])
    }
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }
    const submit = (e) => {
        e.preventDefault();
        const data = { array: inputFields, depot: dep}
        console.log(data)
        UserService.sendSlots(data).then(
            response => {
                console.log(response.data)
            }
        ).catch(err => console.log(err.message))
    }
   

    return(
        <div className="bodyPArt dataArch">
            <h4>Interview slots Management</h4>
            <div className="slotsDiv">
                <label for="depots"><b>Depot</b></label>
                <Select options={depots} onChange={e => setDep(e.value)}/>
                <label for="depots"><b>Add slots</b></label>
                {inputFields.map((input, index) => {
                    return (
                        <div key={index} className="flex">
                            <div className="timeDiv">
                                <p className="pTime">Date</p>
                                <input
                                    name='date'
                                    type='date'
                                    placeholder='Date'
                                    value={input.name}
                                    onChange={event => handleChange(index, event)}
                                />
                            </div>
                            <div className="timeDiv">
                                <p className="pTime">Ammount</p>
                                <input
                                    name='amount'
                                    placeholder='Amount'
                                    value={input.age}
                                    onChange={event => handleChange(index, event)}
                                />
                            </div>
                            <div className="timeDiv">
                                <p className="pTime">Time</p>
                                <TimePicker 
                                    name='time'
                                    placeholder='time'
                                    value={input.time}
                                    onChange={event => handleChangeTime(index, event, 'time')}
                                />
                                {/*<button style={{marginLeft: '0.5em'}} onClick={() => removeFields(index)}>Remove</button>*/}
                                <button class="buttonSkOn" style={{marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={() => removeFields(index)}>
                                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Remove</span>
                                    <div class="fill-container"></div>
                                </button>
                            </div>
                        </div>
                    )
                })}
                {/*<button onClick={addFields}>Add</button>*/}
                <button class="buttonSkOn" style={{marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={addFields}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Add</span>
                    <div class="fill-container"></div>
                </button>
                {/*<button onClick={submit}>Submit</button>*/}
                <button class="buttonSkOn" style={{marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={submit}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Submit</span>
                    <div class="fill-container"></div>
                </button>
            </div>
        </div>
    )
}
export default SlotsForm;