import React from "react";
import { useState, useEffect } from "react";
import CardMedia from '@mui/material/CardMedia';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import axios from "axios";

const Picture = (props) =>{
    const [ loaded, setLoaded ] = useState(true)
    const [ link, setLink ] = useState()

    useEffect(() =>{
        setLink(props.link)
        axios.get(props.link).then(() => setLoaded(true)).catch(()=> setLoaded(false))
    },[props.link])

    return(
        <>
        {
        loaded ? <CardMedia
                    component="img"
                    height="210"
                    style={{ width: "285px"}}
                    src={encodeURI(link)} 
                    onError={(err)=>{ setLoaded(false)}}                   
                    alt='green iguana'
                /> : <AccountCircleOutlinedIcon style={{marginLeft: '30px',paddingLeft: '20px' ,fontSize: '200px', color: '#e2e2e2'}} />
        }
        </>
    )
}
export default Picture;