import React from "react";
import { useState, useEffect } from "react";
import Select from 'react-select';
import UserService from "../../services/user.service";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%',maxWidth: '101%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: '10',
        width: '101%',
        //paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const DspScorecard = () =>{
    const [ depots, setDepots ] = useState([])
    const [ depot, setDepot ] = useState()
    const [ newDepot, setNewDepot ] = useState('SKSO-')
    const [ delDepot, setDelDepot ] = useState()
    const [ depotsOptions, setDepotOptions ] = useState([])
    const [ openAdd, setOpenAdd ] = useState(false)
    const [ openDel, setOpenDel ] = useState(false)
    const [ scorecards, setScorecards ] = useState()

    useEffect(()=>{
        UserService.getDepotList().then(
            response =>{
                setDepots(response.data)
            }
        )
    },[])
    useEffect(()=>{
        setDepotOptions([])
        depots?.map(row =>{
            setDepotOptions(oldDepotOptions => [...oldDepotOptions, {value: row.depots, label: row.depots}])
            //depotsOptions.push({value: row.depots, label: row.depots})
        })
    },[depots])

    const handleOpenAdd = () =>{
        setOpenAdd(true)
    }
    const handleCloseAdd = () =>{
        setOpenAdd(false)
    }
    const handleOpenDel = () =>{
        setOpenDel(true)
    }
    const handleCloseDel = () =>{
        setOpenDel(false)
    }
    const handleAddNew = () =>{
        UserService.insertDepot({depots: newDepot}).then(
            response =>{
                console.log(response)
            }
        )
        setOpenAdd(false)
        window.location.reload(false)
    }
    const handleDelDepot = () =>{
        UserService.deleteDepot({depots: delDepot}).then(
            response => {
                console.log(response)
            }
        )
        setOpenDel(false)
        window.location.reload(false)
    }

    const handleStation = (e) =>{
        UserService.depotScorecards({station: e.value}).then(
            response =>{
                setScorecards(response.data)
            }
        )
    }

    useEffect(()=>{
        console.log(scorecards)
    },[scorecards])
    return(
        <>
            <h1 className="h1Scorecard">Scorecard Metrics</h1>
            <div className="flex">
                <div className="selectDepotScorecard">
                    <label for="complete" className="label2">Station</label>
                    <Select 
                            options={depotsOptions}
                            styles={colourStyles}
                            //value={{label: status, value: status}}
                            onChange={e => handleStation(e)}
                    /> 
                </div>
                <button className="buttonScorecardAdd" onClick={handleOpenAdd}>+Add Station</button>
                <button className="buttonScorecardAdd" onClick={handleOpenDel}>-Delete Station</button>
            </div>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openAdd}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent >
                        <label for="depotInput" className="label2">New Station</label>
                        <input type="text"  className="depotInput" value={newDepot} onChange={e => setNewDepot(e.target.value?.toUpperCase())}></input>
                    </DialogContent>
                    <DialogActions>
                        <button className="buttonSS" onClick={handleAddNew}>Add</button>
                        <button className="buttonSS" onClick={handleCloseAdd}>Close</button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment sx={{overflow: 'visible'}}>
                <Dialog
                    //fullScreen
                    
                    open={openDel}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{}</DialogTitle>
                    <DialogContent >
                        <label for="depotInput" className="label2">Delete Depot</label>
                        <Select 
                            options={depotsOptions}
                            styles={colourStyles}
                            //value={{label: status, value: status}}
                            onChange={e => setDelDepot(e.value)}
                        /> 
                    </DialogContent>
                    <DialogActions>
                        <button className="buttonSS" onClick={handleDelDepot}>Delete</button>
                        <button className="buttonSS" onClick={handleCloseDel}>Close</button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    )
}
export default DspScorecard;