import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import pdfImg from "./img/pdfIcon.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTabContext } from "@material-ui/lab/TabContext";

const Policies = () =>{
    const [ files, setFiles ] = useState([])
    const [ openPdf, setOpenPdf ] = useState(false)
    const [ pdfName, setPdfName ] = useState()
    const [ logCheck, setLogCheck ] = useState(true)

    useEffect(()=>{
        setFiles([])
        UserService.getPolicies().then(
            response =>{
                let data = response.data
                data?.map(ele =>{
                    setFiles(oldFiles =>[...oldFiles, 
                        <div className="icon"  onClick={(e) => handleOpenPfd(e, ele)}>
                            <img src={pdfImg} className="iconImage"/>
                            <p>{ele}</p>
                        </div>
                    ])
                })
            }
        ).catch(err =>{
            if(err.message == "Request failed with status code 403"){
                setLogCheck(false)
            }
          })
    },[])

    const handleOpenPfd = (e, ele) =>{
        console.log(ele)
        setPdfName(ele)
        setOpenPdf(true)
    }
    const handleShowClosePdf = () =>{
        setOpenPdf(false)
    }

    return(
        <div className="incidentArch">
            {logCheck ? 
            <>
                <h3 className="h3Cap h3TitlesCorrection"><b>Policies</b></h3>
                <div className="polBody">
                    
                    {files}
                </div>
                <React.Fragment>
                    <Dialog
                        fullScreen
                        open={openPdf}
                        //TransitionComponent={Transition}
                        keepMounted
                        //onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description">
                        <DialogTitle>{pdfName}</DialogTitle>
                        <DialogContent>
                            <iframe src={`https://node-skso.co.uk/policies/${pdfName}`} style={{width: '100%', height: '100%'}}/>
                        </DialogContent>
                        <DialogActions>
                            
                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleShowClosePdf}>
                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                                <div class="fill-container"></div>
                            </button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </> : 
            <>
                <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
            </>
            }
        </div>
    )
}
export default Policies;