import React from "react";
import { useState, useEffect } from "react";

const Pictures = (props) =>{
    const [ picturesLocation, setPicturesLocation ] = useState()
    const [ pictures, setPictures ] = useState([])

    useEffect(()=>{
        setPicturesLocation(props.source)    
    },[props])
    
    useEffect(()=>{
        let len = Number(picturesLocation?.split('+')[1])
        let data = []
        for(let i = 0; i < len; i++){
            data.push(<img src={`https://node-skso.co.uk/${picturesLocation?.split('+')[0]}/${i}.${picturesLocation?.split('+')[2]}`} className="incidentPictures"/>)
        }    
        setPictures(data)
    },[picturesLocation])

    return(
        <div className="picturesIncidentsSub">
            {pictures}
        </div>
    )
}
export default Pictures;