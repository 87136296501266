import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, gridClasses, GridFooter, useGridApiRef,GridCellEditStopParams, MuiEvent, renderActionsCell} from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));

const ApplicationView = () =>{
    const [ tableData, setTableData ] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ displayTable, setDisplayTable ] = useState([])
    const [ stations, setStations ] = useState()
    const [ dis, setDis ] = useState()
    const [ logCheck, setLogCheck ] = useState(true)

    useEffect(()=>{
      UserService.getStations().then(
        response =>{
          let data = response.data
          let ele = []
          console.log(data)
          data?.map(row =>{
            ele.push(row.station_code)
          })
          setStations(ele)
        }
      ).catch(err =>{
        if(err.message == "Request failed with status code 403"){
            setLogCheck(false)
        }
      })
    },[])


    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'account_no',
            headerName: 'Account No',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
        {
          field: 'name',
          headerName: 'Name',
          headerClassName: 'table-header',
          width: 288,
          editable: true,
        },
          {
            field: 'station',
            headerName: 'Station',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: stations ? stations : ''
          },
          {
            field: 'phone',
            headerName: 'Phone',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'email',
            headerName: 'Email',
            headerClassName: 'table-header',
            width: 350,
            editable: true,
          },
          {
            field: 'address1',
            headerName: 'Address1',
            headerClassName: 'table-header',
            width: 350,
            editable: true,
          },
          {
            field: 'address2',
            headerName: 'Address2',
            headerClassName: 'table-header',
            width: 300,
            editable: true,
          },
          {
            field: 'town',
            headerName: 'Town',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'county',
            headerName: 'County',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'post_code',
            headerName: 'Post Code',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'nationality',
            headerName: 'Nationality',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dob',
            headerName: 'Dob',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'age',
            headerName: 'Age',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'nino',
            headerName: 'Nino',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'vehicle_source',
            headerName: 'Vehicle Source',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_no',
            headerName: 'Dl no',
            headerClassName: 'table-header',
            width: 300,
            editable: true,
          },
          {
            field: 'dl_expiry',
            headerName: 'Dl Expiry',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB'):  'no' 
            }
          },
          {
            field: 'dl_origin',
            headerName: 'Dl Origin',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_points',
            headerName: 'Dl Points',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_endorsements',
            headerName: 'Dl Endorsements',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'dl_sharecode',
            headerName: 'Dl Sharecode',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'apply_interview_date',
            headerName: 'Interview Date',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: 'date',
            valueFormatter: params => {
              return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
          },
          {
            field: 'apply_interview_time',
            headerName: 'Interview Time',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'apply_lead',
            headerName: 'Lead',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'apply_previous_exp',
            headerName: 'Previous Exp',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'apply_referral',
            headerName: 'Referral',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'apply_source',
            headerName: 'Source',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
          },
          {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'table-header',
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: ["Pending", "Onboarding", "Active", "Inactive", "Not Suitable", "Not Interested", "Offboarded"]
          },
          {
            field: 'notes',
            headerName: 'Notes',
            headerClassName: 'table-header',
            width: 500,
            editable: true,
          },
          {
            field: 'amzl_profile_link',
            headerName: 'Onboarding Notification',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
            renderCell: (params) => {
              const handleClick = () =>{
                let skEmail = "";
                tableData?.map(element =>{
                  if(element.email == params.row.email){
                    skEmail = element.email_skso
                  }
                })
                const data = {assigned_client: 'Skilled Solutions Ltd',  assigned_location: params.row.station, type: 'Client', account_no: params.row.account_no, terms: '-2 Weeks', name: params.row.name, account_manager: 'SKSO RM', phone_no: params.row.phone, email: params.row.email, address1: params.row.address1, address2: params.row.address2, town: params.row.town, county: params.row.county, post_code: params.row.post_code, dob: params.row.dob, nino: params.row.nino, dl_no: params.row.dl_no, dl_issued: params.row.dl_origin, dl_expiry: params.row.dl_expiry, dl_points: params.row.dl_points, tcs: 'No', status: 'Active' ,email_skso: skEmail, vehicle_source: params.row.vehicle_source}
                console.log(data)
                UserService.sendNoti(data).then(
                  response =>{
                    UserService.getApplicationData().then(
                      response =>{
                          setTableData(response.data)
                      }
                    )
                    let display = []
                    tableData?.map(row =>{
                        display.push({
                                id: row.entry,
                                account_no: row.account_no,
                                name: row.name,
                                station: row.station,
                                phone: row.phone,
                                email: row.email,
                                address1: row.address1,
                                address2: row.address2,
                                town: row.town,
                                county: row.county,
                                post_code: row.post_code,
                                nationality: row.nationality,
                                dob: new Date(row.dob),
                                age: row.age,
                                nino: row.nino,
                                vehicle_source: row.vehicle_source,
                                dl_no: row.dl_no,
                                dl_expiry: new Date(row.dl_expiry),
                                dl_origin: row.dl_origin,
                                dl_points: row.dl_points,
                                dl_endorsements: row.dl_endorsements,
                                dl_sharecode: row.dl_sharecode,
                                apply_interview_date: new Date(row.apply_interview_date),
                                apply_interview_time: row.apply_interview_time,
                                apply_lead: row.apply_lead,
                                apply_previous_exp: row.apply_previous_exp,
                                apply_referral: row.apply_referral,
                                apply_source: row.apply_source,
                                status: row.status,
                                notification_status: row.notification_status,
                                notes: row.notes
                        })
                      
                  })
                  setDisplayTable(display)
                  }
                )
                  /**/
                  console.log(params.row)
              }
               return (
                <button class="buttonSkOn" style={{marginTop: '5px', backgroundColor: 'rgb(45, 117, 131)', color:'white', height: '40px', marginLeft: 30}} onClick={handleClick}>
                    <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white', top: '-40%'}}><ForwardToInboxIcon/></span>
                    <div class="fill-container"></div>
                </button>
               )
            }
          },
          {
            field: 'notification_status',
            headerName: 'Notification Status',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
          },
        ]

        const columnsPreview = [
          { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
          {
              field: 'date',
              headerName: 'Date',
              headerClassName: 'table-header',
              width: 150,
              editable: false,
            },
          {
            field: 'dme4',
            headerName: 'DME4',
            headerClassName: 'table-header',
            width: 150,
            editable: false,
          },
            {
              field: 'drm4',
              headerName: 'DRM4',
              headerClassName: 'table-header',
              width: 150,
              editable: false,
            },
            {
              field: 'dss2',
              headerName: 'DSS2',
              headerClassName: 'table-header',
              width: 150,
              editable: false,
            },
            {
              field: 'dxe1',
              headerName: 'DXE1',
              headerClassName: 'table-header',
              width: 150,
              editable: false,
            }
          ]

    useEffect(()=>{
        UserService.getApplicationData().then(
            response =>{
                console.log(response.data)
                setTableData(response.data)
            }
        ).catch(err =>{
          if(err.message == "Request failed with status code 403"){
              setLogCheck(false)
          }
        })
    },[])
    useEffect(()=>{
      let dates = []
     
      tableData?.map(row =>{
        if(!(row.apply_interview_date in dates)){
          console.log(row.apply_interview_date)
          dates.push(row.apply_interview_date)
        }
        
      })
      let filter = [...new Set(dates)]
      let dme4 = []
      let drm4 = []
      let dxe1 = []
      let dss2 = []
      for(let i = 0; i < filter.length; i++){
        dme4.push(0)
        drm4.push(0)
        dxe1.push(0)
        dss2.push(0)
      }
      tableData?.map(row =>{
        for(let i = 0; i < filter.length; i++){
          if(row.apply_interview_date == filter[i] && row.station == 'DME4-SKSO' && row.status == 'Pending'){
            dme4[i] += 1 
          }
          if(row.apply_interview_date == filter[i] && row.station == 'DRM4-SKSO' && row.status == 'Pending'){
            drm4[i] += 1 
          }
          if(row.apply_interview_date == filter[i] && row.station == 'DXE1-SKSO' && row.status == 'Pending'){
            dxe1[i] += 1 
          }
          if(row.apply_interview_date == filter[i] && row.station == 'DSS2-SKSO' && row.status == 'Pending'){
            dss2[i] += 1 
          }
        }
      })
      console.log(dss2)
      console.log(dme4)
      console.log(drm4)
      console.log(dxe1)
      let displayFilter = []
      filter?.map((date, index) =>{
        if(dme4[index] !== 0 || drm4[index] !== 0 || dss2[index] !== 0 || dxe1[index] !== 0){
          displayFilter.push({
            id: index,
            date: date?.split('T')?.length > 1 ? date?.split('T')[0] : date,
            dme4: dme4[index],
            drm4: drm4[index],
            dss2: dss2[index],
            dxe1: dxe1[index]
          })
        }
      })
      setDis(displayFilter)
    },[tableData])
    useEffect(()=>{
        let display = []
        tableData?.map(row =>{
            display.push({
                    id: row.entry,
                    account_no: row.account_no,
                    name: row.name,
                    station: row.station,
                    phone: row.phone,
                    email: row.email,
                    address1: row.address1,
                    address2: row.address2,
                    town: row.town,
                    county: row.county,
                    post_code: row.post_code,
                    nationality: row.nationality,
                    dob: new Date(row.dob),
                    age: row.age,
                    nino: row.nino,
                    vehicle_source: row.vehicle_source,
                    dl_no: row.dl_no,
                    dl_expiry: new Date(row.dl_expiry),
                    dl_origin: row.dl_origin,
                    dl_points: row.dl_points,
                    dl_endorsements: row.dl_endorsements,
                    dl_sharecode: row.dl_sharecode,
                    apply_interview_date: new Date(row.apply_interview_date),
                    apply_interview_time: row.apply_interview_time,
                    apply_lead: row.apply_lead,
                    apply_previous_exp: row.apply_previous_exp,
                    apply_referral: row.apply_referral,
                    apply_source: row.apply_source,
                    status: row.status,
                    notification_status: row.notification_status,
                    notes: row.notes
            })
            
        })
        setDisplayTable(display)
        
    },[tableData])

    const handleCellEdit = (params) =>{
        function dateCorrection(date){
          return new Date(date).toLocaleDateString('en-Gb').split('/')[2] + '-' + new Date(date).toLocaleDateString('en-Gb').split('/')[1] + '-' + new Date(date).toLocaleDateString('en-Gb').split('/')[0]
        }
        console.log(params)
        const data = {
                entry: params.entry,
                account_no: params.account_no,
                name: params.name,
                station: params.station,
                phone: params.phone,
                email: params.email,
                address1: params.address1,
                address2: params.address2,
                town: params.town,
                county: params.county,
                post_code: params.post_code,
                nationality: params.nationality,
                dob: params.dob ? dateCorrection(params.dob) : '',
                age: params.age,
                nino: params.nino,
                vehicle_source: params.vehicle_source,
                dl_no: params.dl_no,
                dl_expiry: params.dl_expiry ? dateCorrection(params.dl_expiry) : '',
                dl_origin: params.dl_origin,
                dl_points: params.dl_points,
                dl_endorsements: params.dl_endorsements,
                dl_sharecode: params.dl_sharecode,
                apply_interview_date: params.apply_interview_date ? dateCorrection(params.apply_interview_date) : '',
                apply_interview_time: params.apply_interview_time,
                apply_lead: params.apply_lead,
                apply_previous_exp: params.apply_previous_exp,
                apply_referral: params.apply_referral,
                apply_source: params.apply_source,
                status: params.status,
                notification_status: params.notification_status,
                notes: params.notes
        }
        tableData?.map((ele, index) =>{
          if(ele.name == params.name){
            tableData[index] = {
              entry: params.id,
              account_no: params.account_no,
              name: params.name,
              station: params.station,
              phone: params.phone,
              email: params.email,
              address1: params.address1,
              address2: params.address2,
              town: params.town,
              county: params.county,
              post_code: params.post_code,
              nationality: params.nationality,
              dob: params.dob,
              age: params.age,
              nino: params.nino,
              vehicle_source: params.vehicle_source,
              dl_no: params.dl_no,
              dl_expiry: params.dl_expiry,
              dl_origin: params.dl_origin,
              dl_points: params.dl_points,
              dl_endorsements: params.dl_endorsements,
              dl_sharecode: params.dl_sharecode,
              apply_interview_date: params.apply_interview_date,
              apply_interview_time: params.apply_interview_time,
              apply_lead: params.apply_lead,
              apply_previous_exp: params.apply_previous_exp,
              apply_referral: params.apply_referral,
              apply_source: params.apply_source,
              status: params.status,
              notification_status: params.notification_status,
              notes: params.notes
            }
          }
        })
        UserService.updateApplicationData(data).then(
            response =>{
                console.log(response)
                
            }
        ).catch(err =>{console.log(err)})
        return params
    }

    const handldeInput = (e) =>{
        let data = []
        tableData?.map(row =>{
            if(row.name?.toLowerCase().match(e.target.value?.toLowerCase())){
                data.push({
                    id: row.entry,
                    account_no: row.account_no,
                    name: row.name,
                    station: row.station,
                    phone: row.phone,
                    email: row.email,
                    address1: row.address1,
                    address2: row.address2,
                    town: row.town,
                    county: row.county,
                    post_code: row.post_code,
                    nationality: row.nationality,
                    dob: new Date(row.dob),
                    age: row.age,
                    nino: row.nino,
                    vehicle_source: row.vehicle_source,
                    dl_no: row.dl_no,
                    dl_expiry: new Date(row.dl_expiry),
                    dl_origin: row.dl_origin,
                    dl_points: row.dl_points,
                    dl_endorsements: row.dl_endorsements,
                    dl_sharecode: row.dl_sharecode,
                    apply_interview_date: new Date(row.apply_interview_date),
                    apply_interview_time: row.apply_interview_time,
                    apply_lead: row.apply_lead,
                    apply_previous_exp: row.apply_previous_exp,
                    apply_referral: row.apply_referral,
                    apply_source: row.apply_source,
                    status: row.status,
                    notification_status: row.notification_status,
                    notes: row.notes
                })
            }
        })
        setDisplayTable(data)
    }
    
    return(
        <>
          {logCheck ? 
            <>  
              <Box sx={{ height: 'fit-content', width: 750, paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGrid
                    processRowUpdate={handleCellEdit}
                    rows={dis ? dis : ''}
                    columns={columnsPreview}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: ''}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    columnVisibilityModel={{
                        id: false
                      }}
                />
            </Box>    

            <div className="flex">
                <input type="text" className="inputIncidents" onChange={e => handldeInput(e)} placeholder="search..."></input>

            </div>
            <Box sx={{ height: 3100, width: 7090, paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGrid
                    processRowUpdate={handleCellEdit}
                    rows={displayTable}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: ''}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    columnVisibilityModel={{
                        id: false
                      }}
                />
            </Box>    
          </>:
          <>
              <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
          </>
          }        
        </>
    )
}
export default ApplicationView;