const Columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
    {
        field: 'account_no',
        headerName: 'Account No',
        headerClassName: 'table-header',
        width: 208,
        editable: false,
      },
      {
        field: 'station',
        headerName: 'Station',
        headerClassName: 'table-header',
        width: 288,
        editable: false,
      },
      {
        field: 'skso_role',
        headerName: 'Skso Role',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'table-header',
      width: 288,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Personal Email',
      headerClassName: 'table-header',
      width: 368,
      editable: false,
    },
    {
      field: 'phone',
      headerName: 'Phone No',
      headerClassName: 'table-header',
      width: 228,
      editable: false,
    },
    {
      field: 'address1',
      headerName: 'Address1',
      headerClassName: 'table-header',
      width: 368,
      editable: false,
    },
    {
      field: 'address2',
      headerName: 'Address2',
      headerClassName: 'table-header',
      width: 368,
      editable: false,
    },
    {
      field: 'town',
      headerName: 'Town',
      headerClassName: 'table-header',
      width: 208,
      editable: false,
    },
    {
      field: 'county',
      headerName: 'County',
      headerClassName: 'table-header',
      width: 208,
      editable: false,
    },
    {
      field: 'post_code',
      headerName: 'Post Code',
      headerClassName: 'table-header',
      width: 208,
      editable: false,
    },
    {
      field: 'nationality',
      headerName: 'Nationality',
      headerClassName: 'table-header',
      width: 208,
      editable: false,
    },
     {
        field: 'dob',
        headerName: 'Dob',
        headerClassName: 'table-header',
        width: 208,
        editable: false,
      },
      {
        field: 'age',
        headerName: 'Age',
        headerClassName: 'table-header',
        width: 208,
        editable: false,
      },
      {
        field: 'nino',
        headerName: 'Nino',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'vehicle_source',
        headerName: 'Vehicle Source',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
      },
      {
        field: 'dl_origin',
        headerName: 'Dl Origin',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'dl_no',
        headerName: 'Dl No',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
      },
      {
        field: 'dl_expiry',
        headerName: 'Dl Expiry',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'dl_points',
        headerName: 'Dl Points',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'apply_interview_date',
        headerName: 'Interview Date',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'apply_interview_time',
        headerName: 'Interview Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'apply_onboarding_skso_acc',
        headerName: 'SKSO Account',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'apply_da_date',
        headerName: 'DA Date',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'apply_da_time',
        headerName: 'DA Time',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'apply_da_result',
        headerName: 'DA Result',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'onboarding_rtw_check',
        headerName: 'RTW Check',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'onboarding_bgc_completed',
        headerName: 'BGC Completed',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'onboarding_date',
        headerName: 'Onboarding Date',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'active_utr',
        headerName: 'Utr',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'active_amzl_id',
        headerName: 'Amzl Id',
        headerClassName: 'table-header',
        width: 300,
        editable: false,
      },
      {
        field: 'active_pay_cycle',
        headerName: 'Pay Cycle',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'active_co_name',
        headerName: 'Company Name',
        headerClassName: 'table-header',
        width: 300,
        editable: false,
      },
      {
        field: 'active_co_reg',
        headerName: 'Company Reg',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'active_co_vat',
        headerName: 'Company Vat',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'active_bank_name',
        headerName: 'Bank Name',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'active_bank',
        headerName: 'Bank Holder',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'active_bank_ref',
        headerName: 'Bank Ref',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'active_sort_code',
        headerName: 'Bank SC',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'active_account_no',
        headerName: 'Bank ACC',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_dl_no',
        headerName: 'Audit Dl No',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_dl_expiry',
        headerName: 'Audit Dl Exp',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_dl_origin',
        headerName: 'Audit Dl Origin',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_dl_points',
        headerName: 'Audit Dl Points',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_dl_endorsements',
        headerName: 'Audit Dl Endorsments',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_dl_check_date',
        headerName: 'Audit Dl Check Date',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_rtw1',
        headerName: 'Audit RTW1',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_rtw1_doc',
        headerName: 'Audit RTW1 Doc',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_rtw1_expiry',
        headerName: 'Audit RTW1 Expiry',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_rtw2',
        headerName: 'Audit RTW2 Doc',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_rtw2_doc',
        headerName: 'Audit RTW2 Doc',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_rtw2_expiry',
        headerName: 'Audit RTW2 Expiry',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'audit_rtw_check',
        headerName: 'Audit RTW Check',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'updatedBy',
        headerName: 'Updated By',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'notes',
        headerName: 'notes',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'offboarding_request_by',
        headerName: 'Offboarding Request By',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'offboarding_date',
        headerName: 'Offboarding Date',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'offboarding_reason',
        headerName: 'Offboarding Reason',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'offboarding_badge_status',
        headerName: 'Offboarding Badge Status',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'offboarding_security_aware',
        headerName: 'Offboarding Security Aware',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },
      {
        field: 'offboarding_action_by',
        headerName: 'Offboarding Action By',
        headerClassName: 'table-header',
        width: 368,
        editable: false,
      },

    /*{
        field: 'edit',
        headerName: '',
        headerClassName: 'table-header',
        width: 288,
        editable: false,
        renderCell: (param) =>(
                <>
                    <EditIcon onClick={e => handleParams(param.row)} onMouseEnter={handlePopoverOpenE} onMouseLeave={handlePopoverCloseE}/>
                    <KeyIcon style={{paddingLeft: '5%', fontSize:'40px'}} onClick={e => handleAccess(param)} onMouseEnter={handlePopoverOpenA} onMouseLeave={handlePopoverCloseA}/>
                    <DeleteIcon style={{paddingLeft: '5%', fontSize:'40px'}} onClick={e => handleAlert(param)} onMouseEnter={handlePopoverOpenD} onMouseLeave={handlePopoverCloseD}/>
                </>
        )
      },*/
    ]
    export default Columns;